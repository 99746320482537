//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-48:_3244,_9640,_7404,_4392,_8800,_620,_9556,_3740;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-48')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-48', "_3244,_9640,_7404,_4392,_8800,_620,_9556,_3740");
        }
      }catch (ex) {
        console.error(ex);
      }